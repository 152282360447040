import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from './Footer'
import Menu from './Menu'
import CtaButton from './CtaButton'

const TemplateWrapper = ({page, children, location, cta}) => {
  const title =
    page.title !== 'Persson Innovation'
      ? page.title + ' - Persson Innovation'
      : 'Persson Innovation'
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <title>{title}</title>
      </Helmet>
      <Menu currentPage={page.url} location={location} page={page} />
      {cta?.btnShow && (
        <CtaButton page={page} {...cta} />
      )}
      <div className={`wrap ${page.slug} page-id-${page.id}`}>
        {children}
        <Footer />
      </div>
    </>
  )}

export default TemplateWrapper
