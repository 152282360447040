import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { Navbar, NavDropdown } from 'react-bootstrap'
import logo from '../../assets/images/logo.svg'
import { HamburgerSpin } from 'react-animated-burgers'

const Menu = ({ page, location, currentPage }) => {
  const shouldIShow = location?.state?.fromMenu ? false : true
  const [showMenu, setShowMenu] = useState(
    page.uri === '/' || location?.key === 'initial' || shouldIShow
      ? false
      : true
  )
  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }
  useEffect(() => {
    setShowMenu(false)
  }, []);

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"/></svg>
)

  const is_current_page = (url) => {
    const currentUrl = page && page.uri ? page.uri : '/'
    if (typeof url === 'object' && url.length > 0) {
      const obj = url.find((e) => e.url === currentUrl)
      return obj ? 'active' : ''
    }
    return (currentUrl === url || currentUrl.includes(url)) ? 'active' : ''
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          menu: wpMenu(name: { eq: "Menu" }) {
            id
            menuItems {
              nodes {
                id
                url
                label
                target
                cssClasses
                childItems {
                  nodes {
                    id
                    url
                    label
                    target
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Navbar
          expanded={showMenu}
          bsPrefix={`navbar navbar-expand-md ${showMenu ? 'active' : ''}`}
        >
          <div className="container">
            <Link to="/" className="navbar-brand">
              <img
                src={logo}
                className="img-logo img-fluid"
                alt="Persson Innovation"
              />
            </Link>
            <HamburgerSpin
              className="navbar-toggler ms-auto pe-0"
              aria-controls="basic-navbar-nav"
              isActive={showMenu}
              toggleButton={toggleMenu}
              barColor="#fff"
              buttonWidth={30}
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <ul className="navbar-nav ms-auto ml-auto justify-items-center">
                {data.menu?.menuItems?.nodes.map((item, i, arr) => {
                  const dropdown =
                    item.childItems.nodes.length > 0 ? true : false
                  return (
                    <li
                      className={`nav-item ${
                        dropdown ? 'dropdown' : ''
                      } ${is_current_page(item.childItems.nodes)}`}
                      key={item.id}
                    >
                      {!dropdown && (
                        <Link
                          to={item.url}
                          state={{ fromMenu: true }}
                          className={`nav-link ${is_current_page(item.url)} ${
                            item.cssClasses
                          }`}
                          target={item.target}
                        >
                          {item.label}
                        </Link>
                      )}

                      {dropdown && (
                        <>
                          <NavDropdown title={item.label}>
                            {item.childItems.nodes.map((item) => (
                              <Link
                                to={item.url}
                                state={{ fromMenu: true }}
                                key={item.id}
                                target={item.target}
                                className={`dropdown-item ${is_current_page(
                                  item.url
                                )}`}
                              >
                                {item.label}
                              </Link>
                            ))}
                          </NavDropdown>
                        </>
                      )}
                    </li>
                  )
                })}
                <li className={`nav-item phone d-md-none`}>
                  <a href="tel:+16307305773" className="btn">
                    <Icon /> US 630-730-5773
                  </a>
                </li>
                <li className={`nav-item phone d-md-none`}>
                  <a href="tel:+4619569262" className="btn">
                    <Icon /> EU 46-19 56 92 62
                  </a>
                </li>
              </ul>
            </Navbar.Collapse>
          </div>
        </Navbar>
      )}
    />
  )
}

Menu.defaultProps = {
  location: { state: { displayMenu : false } },
}

export default Menu
