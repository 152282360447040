import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import parse from 'html-react-parser'

/*
const Facebook = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="currentColor" alt="Facebook">
    <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
  </svg>
)
*/

const LinkedIn = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    fill="currentColor"
    alt="LinkedIn"
  >
    <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
  </svg>
)

const component = () => (
  <footer>
    <StaticQuery
      query={graphql`
        query {
          wp {
            sidfot {
              footer {
                column1 {
                  title
                  text1
                  text2
                }
                column2 {
                  title
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <div className="container">
          <div className="row d-none d-md-flex">
            <div className="col-12 col-md-6">
              {data.wp.sidfot.footer.column1.title && (
                <h4>{data.wp.sidfot.footer.column1.title}</h4>
              )}
            </div>
            {/* <div className="col-12 col-md-4 d-none d-md-flex">
              {data.wp.sidfot.footer.column2.title && (
                <h4>{data.wp.sidfot.footer.column2.title}</h4>
              )}
            </div> */}
          </div>
          <div className="row">
            <div className="col-12 col-md-6 mb-4 mb-md-0 order-1 order-md-0">
              <div className="row">
                <div className="col-12 d-md-none">
                  {data?.wp?.sidfot?.footer?.column1?.title && (
                    <h4>{data.wp.sidfot.footer.column1.title}</h4>
                  )}
                </div>
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                  {parse(data.wp.sidfot.footer.column1.text1)}
                </div>

                <div className="col-12 col-md-5 col-lg-6">
                  {parse(data.wp.sidfot.footer.column1.text2)}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 col-lg-5 mb-4 mb-md-0 align-self-end order-0 order-md-1">
              {/*  
                {data.wp.sidfot.footer.column2.title && (
                  <h4 className="d-md-none">
                    {data.wp.sidfot.footer.column2.title}
                  </h4>
                )}
                <Form />
              */}
            </div>
            <div className="col-12 col-md-2 col-lg-1 align-self-end order-2 sm">
              {/* <a href="https://facebook.com">
                <Facebook />
              </a> */}
              <a
                href="https://www.linkedin.com/company/persson-innovation-inc-/?trk=biz-companies-cym"
                aria-label="Visit our LinkedIn"
              >
                <LinkedIn />
              </a>
            </div>
            <div className="col-12 order-3 recaptcha-text pt-3">
              <p>
                Read our <Link to="/privacy-policy/">privacy policy</Link> |
                reCAPTCHA from Google{' '}
                <a href="https://policies.google.com/privacy" rel="noreferrer">
                  privacy policy
                </a>{' '}
                and{' '}
                <a href="https://policies.google.com/terms" rel="noreferrer">
                  user terms
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      )}
    />
  </footer>
)

export default component
