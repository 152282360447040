import React from 'react'

class Input extends React.Component {

  handleChange(e) {
    const { name } = this.props
    const { value } = e.target
    this.props.onValueChange({ name, value })
  }

  render() {
    return (
      <div className={`form-group ${this.props.required && 'required'}`}>
        <label hidden id={`input${this.props.name}`}>
          {this.props.title}
        </label>
        {this.props.type === 'textarea' && (
          <textarea
            type={this.props.type}
            className="form-control"
            aria-labelledby={`input${this.props.name}`}
            name={this.props.name}
            rows="4"
            required={this.props.required}
            value={this.props.value}
            placeholder={`${this.props.title} ${
              this.props.required ? `*` : ``
            }`}
            maxLength={this.props.maxLength}
            onChange={(e) => this.handleChange(e)}
          />
        )}
        {this.props.type !== 'textarea' && (
          <input
            type={this.props.type}
            className="form-control"
            aria-labelledby={`input${this.props.name}`}
            name={this.props.name}
            required={this.props.required}
            value={this.props.value}
            maxLength={this.props.maxLength}
            placeholder={`${this.props.title} ${
              this.props.required ? `*` : ``
            }`}
            onChange={(e) => this.handleChange(e)}
          />
        )}
        <div className="invalid-feedback">{this.props.error}</div>
      </div>
    )
  }
}
export default Input
