import React, { useState, useRef } from 'react'
import Input from './Input'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const Component = ({ item }) => {
  const form = useRef(null)
  const [formData, setFormData] = useState({
    agreement: 'off',
  })
  const [formClass, setFormClass] = useState('needs-validation')
  const { executeRecaptcha } = useGoogleReCaptcha()
  const type = item?.item?.typ ?? 'sample'

  const formUrl =
    type == 'sample'
      ? 'https://persson.lebowski.se/wp-json/contacthandle/v1/post/'
      : 'https://persson.lebowski.se/wp-json/contactcallback/v1/post/'

  const handleSubmitClick = () => {
    setFormClass('needs-validation was-validated')
  }

  const handleInputChange = (e) => {
    const value = e.value
    const name = e.name
    setFormData( { ...formData, [name]: value } )
  }

  const reduceInputValues = (inputElements) => {
      const arrElements = Array.prototype.slice.call(inputElements)
      const formValues = arrElements
        .filter(elem => elem.name.length > 0)
        .map(x => {
          const { typeMismatch } = x.validity
          const { name, type, value } = x
          return {
            name,
            type,
            typeMismatch, //we use typeMismatch when format is incorrect(e.g. incorrect email)
            value,
            valid: x.checkValidity(),
          }
        })
        .reduce((acc, currVal) => {
          //then we finally use reduce, ready to put it in our state
          const { value, valid, typeMismatch } = currVal
          acc[currVal.name] = {
            value,
            valid,
            typeMismatch,
          }
          return acc
        }, {})
      return formValues
  }

  const checkAllFieldsValid = (inputValues) => {
    return !Object.keys(inputValues)
      .map(x => inputValues[x])
      .some(field => !field.valid)
  }

const handleSubmit = async (e) => {
    e.preventDefault()
    if (!executeRecaptcha) {
      return false
    }
    const tokenCaptcha = await executeRecaptcha('persson')
          .catch((err) => console.log('Error:', err))

    const submitButton = form.current.querySelector('button.submit')
    const inputValues = reduceInputValues(form.current.elements)
    const allFieldsValid = checkAllFieldsValid(inputValues)
    if (allFieldsValid) {
      submitButton.setAttribute('disabled', '')
      submitButton.innerHTML =
        'Sending <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'

      fetch(formUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          debug: false,
          token: tokenCaptcha,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log('Response: ', response, response.data.status)
          if (response.data.status === 200) {
            submitButton.innerHTML = 'Sent!'
            alert(response.message)
          } else {
            console.log('Request failed', response)
            submitButton.removeAttribute('disabled')
            submitButton.innerHTML = 'Try again!'
          }
        })
        .catch(function (error) {
          submitButton.removeAttribute('disabled')
          console.log('Request failed', error)
        })
    } else {
      submitButton.innerHTML = 'Send'
    }
  }


  return (
    <form
      className={formClass}
      action={formUrl}
      method="post"
      onSubmit={handleSubmit}
      ref={form}
      noValidate
    >
      <div className="row gx-2">
        <div className="col-12 col-md-6">
          <Input
            type="text"
            name="name"
            title="Name"
            error="Enter your name."
            required={true}
            value={formData?.name ?? ''}
            onValueChange={(e) => handleInputChange(e)}
            maxLength="96"
          />
        </div>

        {type === 'sample' && (
          <div className="col-12 col-md-6">
            <Input
              type="email"
              name="email"
              title="E-mail"
              error="Enter your email."
              required={true}
              value={formData?.email ?? ''}
              onValueChange={(e) => handleInputChange(e)}
              maxLength="320"
            />
          </div>
        )}
        {type === 'callback' && (
          <div className="col-12 col-md-6">
            <Input
              type="tel"
              name="phone"
              title="Phone number"
              error="Enter your phone number."
              required={true}
              value={formData?.phone ?? ''}
              onValueChange={(e) => handleInputChange(e)}
              maxLength="96"
            />
          </div>
        )}

        <div className="col-12">
          <Input
            type="text"
            name="company"
            title="Company"
            error="Enter your company."
            required={true}
            value={formData?.company ?? ''}
            onValueChange={(e) => handleInputChange(e)}
            maxLength="256"
          />
        </div>
        {type === 'sample' && (
          <div className="col-12">
            <Input
              type="text"
              name="adress"
              title="Address"
              required={true}
              error="Enter your address."
              value={formData?.adress ?? ''}
              onValueChange={(e) => handleInputChange(e)}
              maxLength="256"
            />
          </div>
        )}
        {type === 'callback' && (
          <div className="col-12 message-input">
            <Input
              type="textarea"
              name="message"
              title="Message"
              required={false}
              error="Enter your message."
              value={formData?.message ?? ''}
              maxLength="320"
              onValueChange={(e) => handleInputChange(e)}
            />
          </div>
        )}
      </div>
      <div className="form-group form-check">
        <input
          type="checkbox"
          className="form-check-input"
          aria-labelledby="inputAgreement"
          name="agreement"
          id="inputAgreementCheckbox"
          onChange={(e) => handleInputChange(e)}
          value={formData?.agreement ?? 'off'}
          required
        />
        <label
          className="form-check-label"
          id="inputAgreement"
          htmlFor="inputAgreementCheckbox"
        >
          I accept to share my personal information with Persson Innovation.
        </label>
        <div className="invalid-feedback">You must accept the terms.</div>
      </div>
      <button type="submit" className="submit btn" onClick={handleSubmitClick}>
        Send
      </button>
    </form>
  )
  }
export default Component
