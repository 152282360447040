import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Modal } from 'react-bootstrap'
import Form from './form/FormData'
import Img from 'gatsby-image'
import parse from 'html-react-parser'
function CtaModal(props) {
  const { form } = props
  const images = form?.imageMobile
    ? [
        form.imageMobile.localFile.childImageSharp.fluid,
        {
          ...form.image.localFile.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
      ]
    : form?.image
    ? form?.image.localFile.childImageSharp.fluid
    : false
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <div className={`section form freehandle`}>
          {images && (
            <Img
              fluid={images}
              className="no-padding-image"
              alt={form.image.altText}
            />
          )}
          <div className="container">
            <div className={`row justify-content-end`}>
              <div className="col-12 col-md-6">
                {form?.title && <h2>{parse(form?.title)}</h2>}
                {form?.text && parse(form.text)}
                <Form />
                {form?.textBottom && parse(form?.textBottom)}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const Component = ({ btnSvg, btnLink, btnAltText, btnFilter, page, form}) => {
  const [modalShow, setModalShow] = useState(false)
  const hideButton = btnFilter && btnFilter
    .map((filter) => filter.databaseId)
    .includes(page.databaseId)
  return (
    <>
      {btnSvg?.localFile?.publicURL && !hideButton && (
        <cta-button>
          <motion.button
            onClick={() => setModalShow(!modalShow)}
            whileTap={{ scale: 0.9 }}
            whileHover={{ scale: 1.05 }}
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              padding: 0,
              opacity: modalShow ? 0 : 1,
              transition: '0.2s',
            }}
          >
            <img src={btnSvg?.localFile?.publicURL} alt={btnAltText} />
          </motion.button>
        </cta-button>
      )}
      <CtaModal
        form={form}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  )}

export default Component
